<template>
  <div>
    <card-table :showTable="false" @edit="addItem">
      <template v-slot:header>

        <div slot="header" class="flex-between el-card__header">
          <span>
            <span>场景列表</span>
          </span>
          <div>
            <el-button size="small" type="primary" @click="addItem">新增场景</el-button>
            <el-button size="small" type="primary" @click="addFixedItem">{{ fixedSceneTitle }}</el-button>
          </div> 
        </div>
      </template>
      <el-table :data="data" :span-method="objectSpanMethod" border>
        <el-table-column prop="name" label="场景名称" width="200" align="center"> </el-table-column>
        <el-table-column prop="condition" label="触发条件"> </el-table-column>
        <el-table-column prop="task" label="执行任务"> </el-table-column>
        <el-table-column prop="defaultFlagStr" label="是否为预设场景" width="200" align="center"> </el-table-column>
        <el-table-column prop="commonFlag" label="是否为常用场景" width="200" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.commonFlag"
              :inactive-value="0"
              :active-value="1"
              @change="changeCommonFlag(scope.row)"
              active-color="#00c4b3"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope" v-if="scope.row.immutableFlag == 1">
            <table-button size="mini" type="primary" @click="editItem(scope.row)">编辑</table-button>
            <table-button size="mini" type="danger" @click="delItem(scope.row.id)">删除</table-button>
          </template>
        </el-table-column>
      </el-table>
    </card-table>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="fixedSceneTitle"
      :visible.sync="visible"
      width="540px"
      :before-close="handleClose"
    >
      
      <el-table border :data="tableData">
        <el-table-column prop="name" label="场景名称">
          <template #default="scope">
            <el-input style="width:170px" v-model="scope.row.name"/>
          </template>
        </el-table-column>
        <el-table-column prop="sceneNo" label="场景编号">
          <template #default="scope">
            <el-input style="width:170px" v-model="scope.row.sceneNo"/>
          </template>
        </el-table-column>
        <el-table-column prop="icon" label="场景图标" width="80px">
          <template #default="scope">
            <img :src="scope.row.icon" alt="">
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ApiRoomScene, ApiBuilding } from "@/api/building";
export default {
  name: "SceneList",
  data() {
    return {
      data: [],
      tableData:[],
      visible:false,
      fixedSceneTitle:'新增固定场景'
    };
  },
  props: ["breadcrumbs"],
  mounted() {
    this.refreshTableData();
    this.getFixedSceneFlag();
  },
  methods: {
    getFixedSceneFlag(){
      ApiBuilding.getFixedSceneButtonFlag(this.$route.query.houseTemplateId).then(res=>{
        this.fixedSceneTitle = res?'编辑固定场景':'新增固定场景'
      })
    },
    addFixedItem(){
      this.visible = true;
      ApiBuilding.getFixedSceneList(this.$route.query.houseTemplateId).then(res=>{
        this.tableData = res.details || []
      })
    },
    handleClose(){
      this.visible = false;
    },
    submit(){
      let param = {
        details:this.tableData,
        templateId: this.$route.query.houseTemplateId
      }
      ApiBuilding.addFixedScene(param).then(()=>{
        this.visible = false;
        this.refreshTableData();
        this.getFixedSceneFlag();
      })
    },
    objectSpanMethod({ row, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 3 || columnIndex === 4) {
        if (row.show) {
          return {
            rowspan: row.rowspan,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      return;
    },
    refreshTableData() {
      ApiRoomScene.list(this.$route.query.houseTemplateId).then((res) => {
        let tableArray = [];
        res.forEach((e) => {
          if (e.sketch) {
            e.sketch?.forEach((sk, idx) => {
              tableArray.push({
                ...e,
                condition: sk.condition,
                task: sk.task,
                defaultFlagStr: e.defaultFlag == 1 ? "是" : "否",
                rowspan: e.sketch.length,
                show: idx === 0
              });
            });
          } else {
            tableArray.push({
              ...e,
              condition: "",
              task: "",
              rowspan: 1,
              show: true,
              defaultFlagStr: e.defaultFlag == 1 ? "是" : "否"
            });
          }
        });
        // this.data = tableArray.length ? tableArray : res;
        this.data = tableArray;
      });
    },
    addItem() {
      this.$router.push({
        path: "/building/projectList/projectDetail/scene/SceneDetail",
        query: { ...this.$route.query, breadcrumbs: JSON.stringify(this.breadcrumbs) }
      });
    },
    editItem(row) {
      this.$router.push({
        path: "/building/projectList/projectDetail/scene/SceneDetail",
        query: { ...this.$route.query, sceneId: row.id, breadcrumbs: JSON.stringify(this.breadcrumbs) }
      });
    },
    delItem(id) {
      this.$delItem(() => {
        let m = {
          id,
          projectId: Number(this.$route.query.projectId)
        };
        this.$store.commit("OPEN_FULL_SCREEN_LOADING");
        ApiRoomScene.del(m)
          .then(() => {
            this.$message.success("删除成功");
            this.refreshTableData();
          })
          .finally(() => {
            this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
          });
      });
    },
    changeCommonFlag(row) {
      ApiRoomScene.swtichCommonFlag(row.id).catch(() => {
        row.commonFlag = row.commonFlag == 1 ? 0 : 1;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: $input-width;
}
::v-deep .el-table__row td:nth-child(2) {
  border-right: 1px solid #ebeef5 !important;
}
</style>
