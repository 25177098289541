<template>
  <div>
    <base-list-page
      :tableModel="tableModel"
      @change="refreshTableData"
      addBtn="新增户型"
      @addItem="addItem"
      @handleBtn="handleBtn"
      @delItem="delItem"
      :hideBtnIcon="true"
    >
      <template v-slot:button>
        <el-button class="btn" type="primary" size="small" @click="downloadTemplate" :loading="downloadLoading"
          >下载配置导入模板</el-button
        >
      </template>
      <template v-slot:fixedButtons>
        <el-table-column fixed="right" label="操作" width="560">
          <template slot-scope="scope">
            <table-button type="primary" @click.native.prevent="setTempRange(scope.row)">温度设定配置</table-button>
            <table-button type="primary" @click.native.prevent="editItem(scope.row)">修改户型名称</table-button>
            <table-button type="info" @click.native.prevent="toHouseTemplate(scope.row)">配置户型</table-button>
            <table-button type="info" @click.native.prevent="importConfig(scope.row)">配置导入</table-button>
            <table-button type="info" @click.native.prevent="copyTemplate(scope.row)">复制户型</table-button>
            <table-button v-if="scope.row.familyNum<1" type="danger" @click="delItem(scope.row.id)"> 删除 </table-button>
          </template>
        </el-table-column>
      </template>
    </base-list-page>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="title"
      :visible.sync="visible"
      width="540px"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="户型名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="户型面积" prop="area">
          <el-input v-model="ruleForm.area" placeholder="请输入">
            <template slot="append">m²</template>
          </el-input>
        </el-form-item>
        <el-form-item label="楼层选择" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择" clearable :disabled="title == '编辑户型'">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="加湿" prop="humidification">
          <el-switch v-model="ruleForm.humidification"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 户型复制-->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="复制户型"
      :visible.sync="visibleCopy"
      width="540px"
      :before-close="handleClose"
    >
      <el-form :model="copyForm" :rules="rules" ref="copyForm" label-width="100px">
        <el-form-item label="户型名称" prop="name">
          <el-input v-model="copyForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="户型面积" prop="area">
          <el-input v-model="copyForm.area" placeholder="请输入">
            <template slot="append">m²</template>
          </el-input>
        </el-form-item>
        <el-form-item label="加湿" prop="humidification">
          <el-switch v-model="copyForm.humidification"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">取 消</el-button>
        <el-button  type="primary" @click="submitCopy" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <!-- todo  温度设定配置-->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="温度设定配置"
      :visible.sync="tempVisible"
      width="540px"
      :before-close="handleTempClose"
    >
      <el-form label-width="100px">
        <el-form-item :label="item.name" :prop="item.code" v-for="item in ranges" :key="item.code">
          <el-input-number v-model="item.min" size="small"></el-input-number>
          <span style="display: inline-block; padding: 0 20px"> - </span>
          <el-input-number v-model="item.max" size="small"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleTempClose" size="small">取 消</el-button>
        <el-button type="primary" @click="tempConfigSubmit" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <import-config-dialog
      v-if="importConfigDialogVisible"
      :detail="detail"
      :projectId="projectId"
      @close="importConfigDialogVisible = false"
    ></import-config-dialog>
  </div>
</template>

<script>
import { ApiHouseTemplate } from "@/api/building";
import { baseTableProperty, colProperty } from "@/components/baseTable/baseTableProperty";
import { downLoad } from "@/utils/util";
import ImportConfigDialog from "../components/ImportConfigDialog";

export default {
  name: "HouseTemplate",
  components: {
    ImportConfigDialog
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableModel: null,
      ruleForm: {
        type: "",
        area: "",
        name: "",
        humidification: false
      },
      copyForm:{
        area: "",
        name: "",
        humidification: false
      },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "change" }]
      },
      visible: false,
      visibleCopy: false,
      typeOptions: null,
      id: null,
      projectId: Number(this.$route.query.projectId),
      downloadLoading: false,
      importConfigDialogVisible: false,
      detail: null,
      tempVisible: false,
      ranges: []
    };
  },
  computed: {
    title() {
      return this.id ? "编辑户型" : "新增户型";
    }
  },
  mounted() {
    this.initOptions();
    this.initTableList();
  },
  methods: {
    initOptions() {
      ApiHouseTemplate.typeOptions().then((res) => {
        this.typeOptions = res;
      });
    },
    initTableList() {
      let model = new baseTableProperty();
      model.colProperty = [
        colProperty.Init("户型名称", "name", "200px"),
        colProperty.Init("户型面积", "areaStr"),
        colProperty.Init("房间数", "roomNum"),
        colProperty.Init("房屋数量", "familyNum"),
      ];
      this.tableModel = model;
      // model.opBtns = [
      //   btnProperty.Init("修改户型名称", "primary"),
      //   btnProperty.Init("配置户型"),
      //   btnProperty.Init("删除")
      // ];
      // model.opBtnsWidth = "300px";
      this.refreshTableData();
    },
    refreshTableData() {
      ApiHouseTemplate.list(this.projectId).then((res) => {
        res.forEach((e) => {
          e.areaStr = e.area + "m²";
        });
        this.tableModel.setData(res);
      });
    },
    addItem() {
      this.visible = true;
    },
    handleBtn({ btn, data }) {
      switch (btn.label) {
        case "修改户型名称":
          this.editItem(data);
          break;
        case "配置户型":
          this.$router.push({
            path: "/building/projectList/projectDetail/houseTemplate/houseTemplateSetting",
            query: {
              ...this.$route.query,
              houseTemplateId: data.id,
              houseTemplateName: data.name,
              houseTemplateType: data.type,
              breadcrumbs: JSON.stringify(this.breadcrumbs)
            }
          });
          break;
      }
    },
    toHouseTemplate(data) {
      this.$router.push({
        path: "/building/projectList/projectDetail/houseTemplate/houseTemplateSetting",
        query: {
          ...this.$route.query,
          houseTemplateId: data.id,
          houseTemplateName: data.name,
          houseTemplateType: data.type,
          breadcrumbs: JSON.stringify(this.breadcrumbs)
        }
      });
    },
    setTempRange(row) {
      this.id = row.id;
      ApiHouseTemplate.getTempRangeConfig(row.id).then((res) => {
        res.forEach((e) => {
          e.min = e.min ? parseFloat(e.min) : 0;
          e.max = e.max ? parseFloat(e.max) : 0;
        });
        this.$nextTick(() => {
          this.ranges = res;
          this.tempVisible = true;
        });
      });
    },
    editItem(row) {
      this.visible = true;
      this.id = row.id;
      this.$nextTick(() => {
        this.ruleForm.name = row.name;
        this.ruleForm.type = row.type;
        this.ruleForm.area = row.area;
        this.ruleForm.humidification = row.humidification == 1;
      });
    },
    delItem(id) {
      this.$confirm("此操作将永久删除该项, 是否继续?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      })
        .then(() => {
          let m = {
              id,
              projectId: this.projectId
          };
          this.$store.commit("OPEN_FULL_SCREEN_LOADING");
          ApiHouseTemplate.del(m)
            .then(() => {
              this.$message.success("删除成功");
              this.refreshTableData();
            })
            .finally(() => {
              this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
            });
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });


     
    },
    handleTempClose() {
      this.tempVisible = false;
      this.ranges = [];
      this.id = null;
    },
    tempConfigSubmit() {
      let m = {
        templateId: this.id,
        data: JSON.parse(JSON.stringify(this.ranges))
      };
      m.data.forEach((e) => {
        if (e.min == 0) e.min = null;
        if (e.max == 0) e.max = null;
      });

      ApiHouseTemplate.setTempRangeConfig(m).then(() => {
        this.$message.success("设置成功");
        this.ranges = [];
        this.tempVisible = false;
      });
    },
    handleClose() {
      this.visible = false;
      this.visibleCopy = false;
      this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
      this.$refs.copyForm && this.$refs.copyForm.resetFields();
      this.id = null;
    },
    copyTemplate(row){
      this.visibleCopy = true;
      this.id = row.id;
    },
    submitCopy(){
      this.$refs.copyForm.validate((valid) => {
        if (valid) {
          let m = { ...this.copyForm, projectId: this.projectId, realestateId: Number(this.$route.query.realestateId) };
          if (this.id) {
            m.id = this.id;
          }
          m.type = null;
          m.humidification = m.humidification ? 1 : 0;
          this.$store.commit("OPEN_FULL_SCREEN_LOADING");
          ApiHouseTemplate.copy(m)
            .then(() => {
              this.$message.success(`复制成功`);
              this.handleClose();
              this.refreshTableData();
            })
            .finally(() => {
              this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
            });
        }
      });
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let m = { ...this.ruleForm, projectId: this.projectId, realestateId: Number(this.$route.query.realestateId) };
          if (this.id) {
            m.id = this.id;
          }
          m.humidification = m.humidification ? 1 : 0;
          this.$store.commit("OPEN_FULL_SCREEN_LOADING");
          let func = m.id ? "update" : "add";
          ApiHouseTemplate[func](m)
            .then(() => {
              this.$message.success(`${m.id ? "修改" : "新增"}成功`);
              this.handleClose();
              this.refreshTableData();
            })
            .finally(() => {
              this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
            });
        }
      });
    },
    // 下载模板
    async downloadTemplate() {
      this.downloadLoading = true;
      try {
        const { filename, blob } = await ApiHouseTemplate.downloadTemplate();
        downLoad(blob, decodeURI(filename));
      } catch (error) {
        console.log(error);
      }
      this.downloadLoading = false;
    },
    // 配置导入
    importConfig(row) {
      this.detail = row;
      this.importConfigDialogVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: $input-width;
}
</style>
